<template>
  <div>
    <b-card
      v-if="$store.state.clients.clientData"
      class="m-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
        >
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="$store.state.clients.clientData.profile.logo"
                :text="avatarText($store.state.clients.clientData.profile.name)"
                size="100px"
                rounded
              />
            </template>
          </b-media>

        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <h4>{{ $store.state.clients.clientData.profile.name }}</h4>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo">
              <b-img
                :src="appLogoImage"
                alt="logo"
              />
            </span>
          </b-link>

        </b-col>
      </b-row>
    </b-card>
    <b-card class="m-1">
      <h4>Usuário cadastrado</h4>
      <p>Obrigado seus dados foram registrados, o seu cadastro será agora verificado e um email será enviado para confirmar o cadastro ou pedir eventuais correções</p>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BMedia, BAvatar, BLink, BImg,
  BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BLink,
    BImg,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  beforeCreate() {
  },
  created() {
    this.$store.dispatch('clients/getClientInfo', this.clientId).then(() => {
      this.loading = false
    })
  },
  methods: {

  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      avatarText,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
